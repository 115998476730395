.form-block {
  border: 1px solid #e7eaf3;
  background-color: white;
  margin-bottom: $grid-gutter-width / 3;
  border-radius: $border-radius;
  box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
  transition: all .3s;
  display: block;
  padding: $grid-gutter-width / 3;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width / 2;
  }
  strong {
    display: block;
    color: $body-color;
  }
  small {
    color: #6c757e;
  }
  &:hover {
    text-decoration: none;
    cursor: pointer;
    strong, small {
      color: $primary;
    }
  }
}
