
#timeSelect {
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 10px;
  padding: 0px 23px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(0, 0, 0);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 66px;
  font-weight: 500;
  line-height: 40px;
}

/* This is a hack to hide the InfoWindow close button */
.gm-style-iw.gm-style-iw-c button {
  display: none !important;
}