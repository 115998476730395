.navbar {
  background-color: white;
  border-bottom: 1px solid #e7eaf3;

  .navbar-brand {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    // background-image: url('images/Rogo-Logo-300x119.png');
    padding: 0;
    height: 40px;
    width: 110px;
    @include media-breakpoint-up(md) {
      width: calc(100% / 4);
    }
    @include media-breakpoint-up(lg) {
      width: calc(100% / 6);
    }
  }

  .dropdown.nav-item {
    .dropdown-toggle.nav-link {
      border: 1px solid #e7eaf3;
      border-radius: 3px;
      padding: 5px 15px;
    }
  }

  .form-inline {
    position: relative;
    .form-control {
      border-color: #e7eaf3;
      border-radius: 3px;
      height: 36px;
      &:focus {
        box-shadow: none;
        outline: none;
        border-color: $input-border-color;
      }
    }
  }

  .user {
    vertical-align: middle;
    display: inline-block;
    padding: 3px 0 0;
    margin-left: $grid-gutter-width;
    .btn {
      margin-left: $grid-gutter-width / 2;
    }
  }

}
