.form-group-results {
  margin: $grid-gutter-width 0;
  padding: 0;
  list-style: none;
  li {
    display: block;
    position: relative;
    padding-right: 30px;
    & + li {
      border-top: 1px solid #e7eaf3;
      padding-top: $grid-gutter-width / 3;
      margin-top: $grid-gutter-width / 3;
      .btn {
        top: 6px;
      }
    }
    .btn {
      position: absolute;
      right: 0;
      top: -4px;
      color: $danger;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
