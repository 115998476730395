.route-login {
  .row {
    min-height: 100vh;
  }
  form {
    background: white;
    padding: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
  }
  img {
    max-width: 230px;
    display: block;
    margin: $grid-gutter-width auto;
  }
}
