.layout {

}
.navbar.fixed-top + .layout {
  margin-top: 56px;
//  margin-top: $nav-link-height;
//  padding-top: $grid-gutter-width;
}

.layout-body {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  main {
    & > .row {
      margin-left: $grid-gutter-width / -2;
      margin-right: $grid-gutter-width / -2;
      & > [class^="col"] {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
      }
    }
  }
}

.container-offcanvas {
  flex-grow: 1;
  display: flex;
  padding-top: $nav-link-height;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.offcanvas-toggle {
  display: inline-block;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.row-offcanvas {
  position: relative;
  transition: all .25s ease-out;
  transform: translateX(0);
  flex-grow: 1;
  @include media-breakpoint-up(md) {
    main {
      overflow-y: auto;
      min-height: 100vh;
      padding: $grid-gutter-width;
      margin: 0 $grid-gutter-width / -2 ;
    }
  }

  &.active {
    transform: translateX(60%);
    @include media-breakpoint-up(md) {
      transform: none;
    }
  }

}

.layout-side-navigation {
  transform: translateX(-100%);
  padding-left: 0;
  padding-right: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  max-width: none;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #e7eaf3;
  }

  @include media-breakpoint-up(md) {
    transform: none;
    width: calc(100% / 4);
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% / 6);
  }

  .side-navigation {
    padding: $grid-gutter-width;
    nav {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
        }
        a,
        .nav-link {
          color: #6c757e;
          transition: all .3s;
          line-height: 2;
          padding: 0;
          &:hover {
            color: $primary;
            text-decoration: none;
          }
          &.active {
            color: $primary;
          //  font-weight: bold;
            &:hover {
              color: $primary;
            }
          }
          .fa {
            margin-right: 5px;
          }
        }
      }
    }
  }

}
