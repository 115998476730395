.form-container {
  position: relative;
}

.has-error {
  .form-error {
    position: absolute;
    background-color: theme-color("danger");
    color: white;
    border-radius: 2px;
		padding: 4px 6px;
		margin: 0 0 6px;
    font-size: 12px;
		bottom: 100%;
		right: 0;
    line-height: 1;
    &:after {
			top: 100%;
			right: 5px;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-top-color: theme-color("danger");
			border-width: 5px;
		}
  }
}

.form-error-static {
  position: absolute;
  background-color: theme-color("danger");
  color: white;
  border-radius: 2px;
  padding: 4px 6px;
  margin: 0 0 6px;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}
