.app-head {
  @include clearfix();
}

.app-header {
  margin-bottom: $grid-gutter-width;
  @include media-breakpoint-up(md) {
    float: left;
  }
  h1 {
    margin: 0;
    font-size: 2rem;
  }
  .btn-back {
    color: #6c757e;
    transition: all .3s;
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

.app-actions {
  margin-bottom: $grid-gutter-width;
  margin-top: $grid-gutter-width / 6;
  @include media-breakpoint-up(md) {
    float: right;
  }
  a,
  button {
    & + a,
    & + button {
      margin-left: $grid-gutter-width / 3;
    }
  }
}
