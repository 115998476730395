.box {
  border: 1px solid #e7eaf3;
  background-color: white;
  margin-bottom: $grid-gutter-width;
  border-radius: $border-radius;
  box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
  .box-body {
    padding: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 2;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
  }
  .box-header {
    padding: $grid-gutter-width / 4 $grid-gutter-width / 3;
    border-bottom: 1px solid #e7eaf3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 3 $grid-gutter-width / 2;
    }
    h3 {
      margin: 0;
      text-transform: uppercase;
      display: inline-block;
      font-size: 0.8em;
      font-weight: bold;
    }
  }
  .box-empty {
    text-align: center;
    padding: $grid-gutter-width;
  }
}

.box-table {
  border: 1px solid #e7eaf3;
  background-color: white;
  box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
  .table-actions {
    padding: $grid-gutter-width / 3;
  }
}

.box-tabs {
  .nav-tabs {
    border-bottom-color: #e7eaf3;
    .nav-link,
    .nav-item,
    .nav-link.nav-item {
      color: #6c757e;
      &:hover {
        color: $primary;
        border-color: transparent;
      }
      &.disabled {
        color: #6c757e;
        opacity: .5;
      }
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      border-color: #e7eaf3 #e7eaf3 white #e7eaf3;
      background-color: white;
      color: black;
      &:hover {
        color: black;
        border-color: #e7eaf3 #e7eaf3 white #e7eaf3;
      }
    }
  }
  .tab-content {
    border: 1px solid #e7eaf3;
    border-top-width: 0;
    background-color: white;
    box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
    min-height: $grid-gutter-width;
    .table-actions {
      padding: $grid-gutter-width / 3;
    }
  }
}

.box-stats {
  height: 100%;
  & > div {
    text-align: center;
    background-color: white;
    border: 1px solid #e7eaf3;
    padding: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width;
    box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
    strong {
      color: $body-color;
      font-size: 1.2rem;
    }
    div {
      font-size: 0.9rem;
      color: #6c757e;
    }
  }
}

.box-blocks {
  .box-body {
    padding: 0;
  }
  .block {
    padding: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 2;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
    & + .block {
      border-top: 1px solid #e7eaf3;
    }
    a {
      transition: all .3s;
      strong {
        display: block;
        color: $body-color;
      }
      small {
        color: #6c757e;
      }
      &:hover {
        text-decoration: none;
        strong, small {
          color: $primary;
        }
      }
    }
  }
}

.box-data {
  strong {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: black;
  }
  & > div {
    color: #6c757e;
  }
  &.danger {
    color: $primary;
    background-color: rgba($primary, 0.1);
    padding: $grid-gutter-width / 3;
  }
  & + .box-data {
    margin-top: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-width / 2;
    }
  }
}

.box-map {
  background-color: white;
  border: 1px solid #e7eaf3;
  margin-bottom: $grid-gutter-width;
  box-shadow: 0 0.5rem 1.2rem rgba(189,197,209,.2);
}
