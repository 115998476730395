.react-select {
  .react-select__control {
    border-color: $input-border-color;
    &:hover {
      border-color: $input-border-color;
    }
    &.react-select__control--is-focused {
      border-color: #e4d5ad;
    }
  }
  .react-select__indicator-separator {
    background-color: $input-border-color;
  }
  .react-select__option--is-selected {
    background-color: $primary;
  }
  .react-select__option--is-focused,
  .react-select__option:active {
    background-color: #e4d5ad;
  }
}
